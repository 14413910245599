.rotate {
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.circles {
  position: relative;
  left: -135px;
  top: -95px;
  z-index: 1;
  animation: transform-circles 0.75s ease-in-out infinite alternate;
}

.slight-rotate {
  animation: slight-rotate 0.75s ease-in-out infinite alternate;
  position: relative;
  z-index: 9999;
}
@keyframes slight-rotate {
  0% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes transform-circles {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(0%);
  }
  80% {
    transform: translateX(-220px);
  }
  100% {
    transform: translateX(-220px);
  }
}
.clicked {
  animation: clicked 0.5s ease-in-out;
}

@keyframes clicked {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.headphones-wire {
  position: absolute;
  left: 318px;
  top: 100px;
  animation: transform-headphones 1s ease-in-out 2s forwards;
}

@keyframes transform-headphones {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(150px);
  }
}

.circles-pulse-translate {
  position: absolute;
  left: 60px;
  top: 150px;
  animation: circles-pulse 2s linear infinite alternate;
}

@keyframes circles-pulse {
  0% {
    transform: scale(1) translateX(0%);
    opacity: 1;
  }
  12% {
    opacity: 1;
    transform: scale(1.05) translateX(0%);
  }
  24% {
    opacity: 1;
    transform: scale(1) translateX(0%);
  }
  36% {
    opacity: 1;
    transform: scale(1.05) translateX(0%);
  }
  46% {
    opacity: 1;
    transform: scale(1) translateX(0%);
  }

  47% {
    opacity: 0;
    transform: scale(1) translateX(0%);
  }
  48% {
    opacity: 0;
    transform: scale(1) translateX(310px);
  }
  49% {
    opacity: 1;
    transform: scale(1) translateX(310px);
  }
  62% {
    opacity: 1;
    transform: scale(1.05) translateX(300px);
  }
  76% {
    opacity: 1;
    transform: scale(1) translateX(310px);
  }
  88% {
    opacity: 1;
    transform: scale(1.05) translateX(300px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(310px);
  }
}

.sq1 {
  color: rgba(134, 215, 227, 0.25);
  animation: sq-appearance 0.75s ease-in-out infinite forwards;
}
.sq2 {
  color: rgba(134, 215, 227, 0.5);
  animation: sq-appearance 0.75s ease-in-out 0.1875s infinite forwards;
}
.sq3 {
  color: rgba(134, 215, 227, 0.75);
  animation: sq-appearance 0.75s ease-in-out 0.375s infinite forwards;
}
.sq4 {
  color: rgba(134, 215, 227, 1);
  animation: sq-appearance 0.75s ease-in-out 0.5625s infinite forwards;
}

@keyframes sq-appearance {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.circles-pulse-translate2 {
  position: absolute;
  left: 70px;
  top: 190px;
  animation: circles-pulse2 2s linear infinite alternate;
}
.circles-pulse-translate3 {
  position: absolute;
  left: 70px;
  top: 155px;
  animation: circles-pulse2 2s linear infinite alternate;
}

@keyframes circles-pulse2 {
  0% {
    transform: scale(1) translateX(0%);
    opacity: 1;
  }
  12% {
    opacity: 1;
    transform: scale(1.05) translateX(0%);
  }
  24% {
    opacity: 1;
    transform: scale(1) translateX(0%);
  }
  36% {
    opacity: 1;
    transform: scale(1.05) translateX(0%);
  }
  46% {
    opacity: 1;
    transform: scale(1) translateX(0%);
  }

  47% {
    opacity: 0;
    transform: scale(1) translateX(0%);
  }
  48% {
    opacity: 0;
    transform: scale(1) translateX(210px);
  }
  49% {
    opacity: 1;
    transform: scale(1) translateX(210px);
  }
  62% {
    opacity: 1;
    transform: scale(1.05) translateX(200px);
  }
  76% {
    opacity: 1;
    transform: scale(1) translateX(210px);
  }
  88% {
    opacity: 1;
    transform: scale(1.05) translateX(200px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(210px);
  }
}

.quieter-louder {
  position: absolute;
}
.quieter-louder.clicked {
  animation: clicked 0.5s ease-in-out;
}

@keyframes clicked {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.075);
  }
  100% {
    transform: scale(1);
  }
}

.no-gap {
  animation: widerr 0.3s ease-in-out infinite alternate;
}

@keyframes widerr {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.05, 1);
  }
}

.initial-gap {
  width: 300px;
}

.gap {
  animation: widthh 0.3s ease-in-out infinite alternate;
}

@keyframes widthh {
  0% {
    width: 300px;
  }
  100% {
    width: 340px;
  }
}

.sheep {
  animation: sheepBG 10s linear infinite;
}

@keyframes sheepBG {
  0% {
    content: url(../../resources/images/sheep-head.svg);
  }
  19% {
    content: url(../../resources/images/sheep-head.svg);
  }
  20% {
    content: url(../../resources/images/sheep-head2.svg);
  }
  22% {
    content: url(../../resources/images/sheep-head.svg);
  }
  24% {
    content: url(../../resources/images/sheep-head2.svg);
  }
  26% {
    content: url(../../resources/images/sheep-head.svg);
  }
}

.lama {
  content: url(../../resources/images/lama.svg);
}

.lamaclicked {
  animation: lamaclicked 0.5s linear;
}

@keyframes lamaclicked {
  0% {
    content: url(../../resources/images/lama.svg);
  }
  9% {
    content: url(../../resources/images/lama.svg);
  }
  10% {
    content: url(../../resources/images/lama2.svg);
  }
  70% {
    content: url(../../resources/images/lama2.svg);
  }
  100% {
    content: url(../../resources/images/lama.svg);
  }
}

.fanclicked {
  animation: rotate 0.5s linear forwards;
}

.lama-desc {
  animation: lamaDesc 5s linear infinite;
}

@keyframes lamaDesc {
  0% {
    content: url(../../resources/images/lama-description.svg);
  }
  19% {
    content: url(../../resources/images/lama-description.svg);
  }
  20% {
    content: url(../../resources/images/lama-description2.svg);
  }
  28% {
    content: url(../../resources/images/lama-description.svg);
  }
  36% {
    content: url(../../resources/images/lama-description2.svg);
  }
  44% {
    content: url(../../resources/images/lama-description.svg);
  }
}

.animals-desc {
  animation: animalsDesc 5s linear infinite;
}

@keyframes animalsDesc {
  0% {
    content: url(../../resources/images/animals-description.svg);
  }
  19% {
    content: url(../../resources/images/animals-description.svg);
  }
  20% {
    content: url(../../resources/images/animals-description2.svg);
  }
  28% {
    content: url(../../resources/images/animals-description.svg);
  }
  36% {
    content: url(../../resources/images/animals-description2.svg);
  }
  44% {
    content: url(../../resources/images/animals-description.svg);
  }
}
