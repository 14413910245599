@keyframes borderanimation {
  from {
    border-width: 1px;
  }
  to {
    border-width: 6px;
  }
}

.click-cursor {
  animation: clickanimation 0.25s linear 2 alternate;
}

@keyframes clickanimation {
  from {
    height: 40px;
    width: 34px;
  }
  to {
    height: 50px;
    width: 42.5px;
  }
}

.selected-btn {
  top: 50px;
  left: 50px;
}

.unselected-btn {
  top: 50px;
  left: 70px;
}
