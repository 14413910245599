
.animals-desc-first {
  animation: animalsDesc2 5s linear infinite;
}

@keyframes animalsDesc2 {
  0% {
    content: url(../resources/images/animals-description-first.svg);
  }
  19% {
    content: url(../resources/images/animals-description-first.svg);
  }
  20% {
    content: url(../resources/images/animals-description-first2.svg);
  }
  28% {
    content: url(../resources/images/animals-description-first.svg);
  }
  36% {
    content: url(../resources/images/animals-description-first2.svg);
  }
  44% {
    content: url(../resources/images/animals-description-first.svg);
  }
}

.lama-desc-first {
  animation: lamaDesc2 5s linear infinite;
}

@keyframes lamaDesc2 {
  0% {
    content: url(../resources/images/lama-bubbles.svg);
  }
  19% {
    content: url(../resources/images/lama-bubbles.svg);
  }
  20% {
    content: url(../resources/images/lama-bubbles2.svg);
  }
  28% {
    content: url(../resources/images/lama-bubbles.svg);
  }
  36% {
    content: url(../resources/images/lama-bubbles2.svg);
  }
  44% {
    content: url(../resources/images/lama-bubbles.svg);
  }
}